import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import findMyIdentity from '../components/functions/find-my-identity';
import ItemList from '../components/functions/item-list/item-list';

const PageListMunicipalities = ({
  path,
  data: {
    allMunicipalities: { nodes },
  },
  pageContext,
}) => {
  /**
   *
   * I WANT TO FIND WHO I AM
   * the page is trying to collect and use it's data to create own identity
   *
   */
  const nodeData = findMyIdentity(path, pageContext);

  return (
    <Layout>
      <Seo title={nodeData.name} />
      <ItemList node={nodeData} children={nodes} />
    </Layout>
  );
};
export default PageListMunicipalities;

export const query = graphql`
  query (
    $sort: node__municipalitySortInput
    $filter: node__municipalityFilterInput
    $population: Boolean = true
    $size: Boolean = false
    $density: Boolean = false
    $asl: Boolean = false
    $mention: Boolean = false
    $shareMale: Boolean = false
    $shareFemale: Boolean = false
    $change1y: Boolean = false
    $change5y: Boolean = false
    $changeAll: Boolean = false
    $neighbours: Boolean = false
    $share0_14: Boolean = false
    $share15_64: Boolean = false
    $share65: Boolean = false
    $avgAge: Boolean = false
    $indexOldAge: Boolean = false
    $migrationBalance: Boolean = false
    $naturalIncrease: Boolean = false
    $totalIncrease: Boolean = false
    $weddings: Boolean = false
    $divorces: Boolean = false
    $migrationIn: Boolean = false
    $migrationOut: Boolean = false
    $liveBirth: Boolean = false
    $dead: Boolean = false
    $agroTotal: Boolean = false
    $agroArable: Boolean = false
    $agroHops: Boolean = false
    $agroVineyard: Boolean = false
    $agroGarden: Boolean = false
    $agroOrchard: Boolean = false
    $agroGrassland: Boolean = false
    $nonagroTotal: Boolean = false
    $nonagroForest: Boolean = false
    $nonagroWater: Boolean = false
    $nonagroBuilt: Boolean = false
    $nonagroOther: Boolean = false
    $agroTotalShare: Boolean = false
    $agroArableShare: Boolean = false
    $agroHopsShare: Boolean = false
    $agroVineyardShare: Boolean = false
    $agroGardenShare: Boolean = false
    $agroOrchardShare: Boolean = false
    $agroGrasslandShare: Boolean = false
    $nonagroTotalShare: Boolean = false
    $nonagroForestShare: Boolean = false
    $nonagroWwaterShare: Boolean = false
    $nonagroBuiltShare: Boolean = false
    $nonagroOtherShare: Boolean = false
    $tourismBeds: Boolean = false
    $tourismBedsShare: Boolean = false
    $tourismObjects: Boolean = false
    $tourismOjectsShare: Boolean = false
    $tourmismOverstays: Boolean = false
    $tourismOverstaysShare: Boolean = false
    $tourismTourists: Boolean = false
    $tourismTouristsShare: Boolean = false
  ) {
    allMunicipalities: allNodeMunicipality(filter: $filter, sort: $sort) {
        nodes {
          field_id
          field_type
          field_asl @include(if: $asl )
          field_mention @include(if: $mention )
          field_population_total @include(if: $population )
          field_size_total @include(if: $size)
          field_population_share_male @include(if: $shareMale)
          field_population_share_female @include(if: $shareFemale)
          field_population_density_total @include(if: $density)
          field_population_change_1y_share @include(if: $change1y)
          field_population_change_5y_share @include(if: $change5y)
          field_population_change_alltime_ @include(if: $changeAll)
          field_neighbours_num @include(if: $neighbours)
          field_population_0_14_share @include(if: $share0_14)
          field_population_15_64_share @include(if: $share15_64)
          field_population_65_more_share @include(if: $share65)
          field_population_avg_age @include(if: $avgAge)
          field_population_index_old_age @include(if: $indexOldAge)
          field_migration_balance_total @include(if: $migrationBalance)
          field_natural_increase_total @include(if: $naturalIncrease)
          field_total_increase_total @include(if: $totalIncrease)
          field_weddings_total @include(if: $weddings)
          field_divorces_total @include(if: $divorces)
          field_migration_in_permanent_tot @include(if: $migrationIn)
          field_migration_out_permanent_to @include(if: $migrationOut)
          field_live_birth_total @include(if: $liveBirth)
          field_dead_total @include(if: $dead)
          field_soil_agro_total @include(if: $agroTotal)
          field_soil_agro_arable @include(if: $agroArable)
          field_soil_agro_hops @include(if: $agroHops)
          field_soil_agro_vineyard @include(if: $agroVineyard)
          field_soil_agro_garden @include(if: $agroGarden)
          field_soil_agro_orchard @include(if: $agroOrchard)
          field_soil_agro_grassland @include(if: $agroGrassland)
          field_nonagro_total @include(if: $nonagroTotal)
          field_nonagro_forest @include(if: $nonagroForest)
          field_nonagro_water_area @include(if: $nonagroWater)
          field_nonagro_built_up_area @include(if: $nonagroBuilt)
          field_nonagro_other @include(if: $nonagroOther)
          field_s_agro_sh @include(if: $agroTotalShare)
          field_s_arable_sh @include(if: $agroArableShare)
          field_s_hops_sh @include(if: $agroHopsShare)
          field_s_vineyard_sh @include(if: $agroVineyardShare)
          field_s_garden_sh @include(if: $agroGardenShare)
          field_s_orchard_sh @include(if: $agroOrchardShare)
          field_s_grassland_sh @include(if: $agroGrasslandShare)
          field_s_nonagro_sh @include(if: $nonagroTotalShare)
          field_s_forest_sh @include(if: $nonagroForestShare)
          field_s_water_sh @include(if: $nonagroWwaterShare)
          field_s_built_up_sh @include(if: $nonagroBuiltShare)
          field_s_other_sh @include(if: $nonagroOtherShare)
          field_t_beds @include(if: $tourismBeds)
          field_t_beds_1y_share @include(if: $tourismBedsShare)
          field_t_objects @include(if: $tourismObjects)
          field_t_objects_1y_share @include(if: $tourismOjectsShare)
          field_t_overstays @include(if: $tourmismOverstays)
          field_t_overstays_1y_share @include(if: $tourismOverstaysShare)
          field_t_tourists @include(if: $tourismTourists)
          field_t_tourists_1y_share @include(if: $tourismTouristsShare)
          relationships {
            field_image_coa {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 40
                    quality: 60
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
            }
            field_region {
              field_id
              name
              path {
                alias
              }
            }
            field_structure_area {
              field_id
              field_type
              name
              path {
                alias
              }
            }
            field_structure_district {
              field_id
              field_type
              name
              path {
                alias
              }
            }
            field_structure_county {
              field_id
              field_type
              name
              path {
                alias
              }
            }
          }
          id
          path {
            alias
          }
          title
        }
    }
  }
`;
